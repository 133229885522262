import React, { FC } from 'react'
import SbEditable from 'storyblok-react'
import { ComponentProps } from '..'
import { FullWidthImageContainer } from './styles'
import { LazyImage } from '../lazy-image'

interface FullWidthImageProps {
  image: BlokImage
}

export const FullWidthImage: FC<ComponentProps<FullWidthImageProps>> = ({ blok }) => {
  const { _uid, image } = blok
  const { filename, alt, title } = image || {}

  return (
    <SbEditable content={blok} key={_uid}>
      <FullWidthImageContainer>
        <LazyImage url={filename} alt={alt} title={title} />
      </FullWidthImageContainer>
    </SbEditable>
  )
}

