import styled from 'styled-components'
import { marginsPadding } from '../../styles/mixins'

export const ButtonContainer = styled.button`
  font-size: 1rem;
  padding: ${(props) => props.theme.spacing.md} ${(props) => props.theme.spacing.lg};
  min-width: 160px;
  background-color: ${(props) => props.theme.colors.button.background};
  color: ${(props) => props.theme.colors.button.text};
  border-radius: 3px;
  border: 0;
  ${marginsPadding}
  &:focus {
    outline: none;
  }
`
