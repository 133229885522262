import styled from 'styled-components'
import { above } from '../../styles/mixins'

export const DividerContainer = styled.section`
  margin: ${(props) => props.theme.spacing.lg} auto;
  max-width: ${(props) => props.theme.spacing.xl};

  ${above.md`
    max-width: ${(props) => props.theme.spacing.xxl};
  `}

  hr {
    border-top: 1px solid ${(props) => props.theme.colors.input.border}};
  }
`
