import React, { FC } from 'react'
import SbEditable, { Blok } from 'storyblok-react'
import styled from 'styled-components'
import { ComponentProps } from '..'
import { HeadingStoryblok } from '../../@types/storyblok'

const HeadingContainer = styled.div<Pick<Blok<HeadingStoryblok>,  "textAlignment">>`
  ${({ textAlignment }) => textAlignment ? `text-align:${textAlignment}` : null}
`

const Tag = styled.h1``

export const Heading: FC<ComponentProps<HeadingStoryblok>> = ({ blok }) => {
  const { _uid, text, size, textAlignment } = blok

  return (
    <SbEditable content={blok} key={_uid}>
      <HeadingContainer textAlignment={textAlignment}>
        <Tag as={size}>{text}</Tag>
      </HeadingContainer>
    </SbEditable>
  )
}
