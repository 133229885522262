import React, { FC } from 'react'
import SbEditable from 'storyblok-react'
import { ComponentProps } from '..'
import { Button } from '../button'
import { FormContainer, FormHoneypot, FormLabel, FormRow, FormTextArea, FormTextInput } from './styles'

interface ContactFormProps {
  name: string
  redirect_uri: any
}

export const ContactForm: FC<ComponentProps<ContactFormProps>> = ({ blok }) => {
  const { _uid, name, redirect_uri } = blok

  return (
    <SbEditable content={blok} key={_uid}>
      <FormContainer name={name} method='POST' action={`/${redirect_uri?.cached_url}`} data-netlify='true' data-netlify-honeypot='bot-field'>
        <input type='hidden' name='form-name' value={name} />
        <FormHoneypot aria-hidden>
          <FormLabel>Trick or treat *</FormLabel>
          <FormTextInput name='bot-field' />
        </FormHoneypot>
        <FormRow>
          <FormLabel>Your Name *</FormLabel>
          <FormTextInput name='name' required />
        </FormRow>
        <FormRow>
          <FormLabel>Your Email *</FormLabel>
          <FormTextInput name='email' required />
        </FormRow>
        <FormRow>
          <FormLabel>Subject</FormLabel>
          <FormTextInput name='subject' />
        </FormRow>
        <FormRow>
          <FormLabel>Your Message</FormLabel>
          <FormTextArea name='enquiry' />
        </FormRow>
        <FormRow>
          <Button margin-top-lg type='submit'>
            Submit
          </Button>
        </FormRow>
      </FormContainer>
    </SbEditable>
  )
}
