import styled from 'styled-components'

export const FormContainer = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 640px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.md} 0;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormLabel = styled.label`
  text-align: left;
`

export const FormTextInput = styled.input`
  font-size: 1rem;
  border-radius: 2px;
  margin-bottom: ${(props) => props.theme.spacing.md};
  padding: ${(props) => props.theme.spacing.md};
  border: 1px solid ${(props) => props.theme.colors.input.border};
  &:focus {
    outline: 2px solid ${(props) => props.theme.colors.input.border};
  }
`

export const FormTextArea = styled.textarea`
  border-radius: 2px;
  height: 200px;
  padding: ${(props) => props.theme.spacing.sm};
  border: 1px solid ${(props) => props.theme.colors.input.border};
  &:focus {
    outline: 2px solid ${(props) => props.theme.colors.input.border};
  }
`

export const FormHoneypot = styled.div`
  display: none;
`
