import React, { FC } from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import { ComponentProps } from '..'

interface TextProps {
  text: string
}

export const Text: FC<ComponentProps<TextProps>> = ({ blok }) => {
  const { _uid, text } = blok

  return (
    <SbEditable content={blok} key={_uid}>
      {render(text)}
    </SbEditable>
  )
}
