import React, { FC }  from 'react'
import SbEditable from 'storyblok-react'
import { ComponentProps, ContainerBlockProps } from '..'
import { ContainerElement } from '../container/styles'
import { ConstrainedImage } from '../constrained-image'
import { Divider } from '../divider'
import { FullWidthImage } from '../full-width-image'
import { Grid } from '../grid'
import { Heading } from '../heading'
import { Placeholder } from '../placeholder'
import { Text } from '../text'
import { WorkListing } from '../work-listing'
import { ContactForm } from '../contact-form'

interface ResolverProps {
  blok: any
}

interface ComponentMap {
  [key: string]: FC<ResolverProps>
}

const ContainerBlock: FC<ComponentProps<ContainerBlockProps>> = ({ blok }) => {
  const { children, _uid, align, fixed_width, padding_top, padding_bottom, padding_left, padding_right } = blok
  const paddingProps = {
    [padding_top || '__undefined']: true,
    [padding_bottom || '__undefined']: true,
    [padding_left || '__undefined']: true,
    [padding_right || '__undefined']: true,
  }

  return (
    <SbEditable content={blok} key={_uid}>
      <ContainerElement fixed={fixed_width} align={align} {...paddingProps}>
        {children && children.map((child: ComponentProps<any>) => <Resolver blok={child} key={child._uid} />)}
      </ContainerElement>
    </SbEditable>
  )
}

export const Components: ComponentMap = {
  'full-width-image': FullWidthImage,
  'constrained-image': ConstrainedImage,
  'container': ContainerBlock,
  'heading': Heading,
  'text': Text,
  'grid': Grid,
  'work-listing': WorkListing,
  'contact-form': ContactForm,
  'divider': Divider,
}

export const Resolver: FC<ResolverProps> = ({ blok }) => {
  if (Components[blok.component]) {
    const Component = Components[blok.component]
    return <Component blok={blok} />
  }

  return <Placeholder name={blok.component} />
}
