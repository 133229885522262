import React, { FC } from 'react'
import { Image } from '@storyofams/storyblok-toolkit'
import { LazyImageContainer } from './styles'

interface LazyImageProps {
  url: string
  height?: number
  alt?: string
  title?: string
}

export const LazyImage: FC<LazyImageProps> = ({ url, alt, title }) => {
  if (!url) {
    return null
  }

  return (
    <LazyImageContainer>
      <Image src={url} alt={`${alt}`} title={title} />
    </LazyImageContainer>
  )
}
