import React, { FC } from 'react'
import SbEditable from 'storyblok-react'
import { ComponentProps } from '..'
import { DividerContainer } from './styles'

export const Divider: FC<ComponentProps<{}>> = ({ blok }) => {
  const { _uid } = blok

  return (
    <SbEditable content={blok} key={_uid}>
      <DividerContainer>
        <hr />
      </DividerContainer>
    </SbEditable>
  )
}

