import styled, { css } from 'styled-components'
import { above } from '../../styles/mixins'
import { MaxWidth } from '../../utils'

export const GridContainer = styled.div`
  max-width: ${MaxWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`

export const GridWrapper = styled.div`
  margin: 0 -${(props) => props.theme.spacing.md * 2};
`

type GridItemProps = {
  sizes: {
    [key: string]: number
  }
}

export const GridItem = styled.div<GridItemProps>`
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacing.md};
  ${({ sizes }) => {
    return css`
      ${above.xs`flex-basis: ${sizes.xs * 100}%`}
      ${above.sm`flex-basis: ${sizes.sm * 100}%`}
      ${above.md`flex-basis: ${sizes.md * 100}%`}
      ${above.lg`flex-basis: ${sizes.lg * 100}%`}
      ${above.xl`flex-basis: ${sizes.xl * 100}%`}
    `
  }}
`
