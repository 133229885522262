import React, { FC } from 'react'
import SbEditable from 'storyblok-react'
import { ComponentProps, Link } from '..'
import { LazyImage } from '../lazy-image'

interface WorkListingProps {
  title: string
  thumbnail?: any
  link?: any
}

export const WorkListing: FC<ComponentProps<WorkListingProps>> = ({ blok }) => {
  const { _uid, title, thumbnail, link } = blok
  const { filename, alt, title: thumbnailTitle } = thumbnail

  return (
    <SbEditable content={blok} key={_uid}>
      <Link to={`/${link.cached_url}`}>
        <LazyImage url={filename} alt={alt} title={thumbnailTitle} />
      </Link>
      <h5>
        <Link to={`/${link.cached_url}`}>
          {title}
        </Link>
      </h5>
    </SbEditable>
  )
}
