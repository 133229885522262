import { sbEditable } from "@storyblok/storyblok-editable"
import React, { FC } from 'react'
import { Blok } from 'storyblok-react'
import { useStoryblok } from '../../hooks/useStoryblok'
import { Resolver } from '../resolver'

const Page: FC<Blok<any>> = ({ story: originalStory, location }: Blok<any>) => {
  const story = useStoryblok(originalStory, location)
  const content = typeof story.content === "string" ? JSON.parse(story.content) : story.content
  const resolvedComponents = content.body && content.body.map((child: Blok<any>) => {
    return <Resolver blok={child} key={child._uid}/>
  })

  return (
    <div {...sbEditable(content)}>
      {resolvedComponents}
    </div>
  )
}

export default Page
