import React, { FC } from 'react'
import SbEditable from 'storyblok-react'
import { ComponentProps, Resolver } from '..'
import { GridContainer, GridItem, GridWrapper } from './styles'

interface GridProps {
  content: any
  sm: number
  md: number
  lg: number
  xl: number
  xs: number
}

export const Grid: FC<ComponentProps<GridProps>> = ({ blok }) => {
  const { _uid, content, sm, md, lg, xl, xs } = blok

  return (
    <SbEditable content={blok} key={_uid}>
      <GridWrapper>
        <GridContainer>
          {content && content.map(child => (
            <GridItem key={child._uid} sizes={{
              xs: 1 / xs,
              sm: 1 / sm,
              md: 1 / md,
              lg: 1 / lg,
              xl: 1 / xl,
            }}>
              <Resolver blok={child} />
            </GridItem>
          ))}
        </GridContainer>
      </GridWrapper>
    </SbEditable>
  )
}
